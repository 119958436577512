<template>
  <div class="accounting-create-assignment pt-4 pb-8">
    <p class="title">Assignment Invoice Items</p>
    <vue-bootstrap4-table
        v-if="data"
        :rows="data"
        :columns="columns"
        :config="config" class="mt-4 pb-4 border-bottom-light">

      <template slot="empty-results">
        Keine rechnungspositionen gefunden.
      </template>

    </vue-bootstrap4-table>
  </div>
</template>

<script>

import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table';

export default {
  name: "AssignmentInvoiceItems",
  components: {
    VueBootstrap4Table
  },

  data() {
    return {
      data: [
        {
          "id": 1,
          "billing_number": 65565,
          "billing_date": '21.08.2021',
          "position_description": "Lorem ipsum dolor simit!",
          "amount": 4,
          "single_price": '150€',
          "total_price": '450€'
        },
        {
          "id": 2,
          "billing_number": 65568,
          "billing_date": '21.08.2021',
          "position_description": "Lorem ipsum dolor simit!",
          "amount": 2,
          "single_price": '300',
          "total_price": '900'
        },
        {
          "id": 3,
          "billing_number": 98746,
          "billing_date": '21.08.2021',
          "position_description": "Lorem ipsum dolor simit!",
          "amount": 6,
          "single_price": '250',
          "total_price": '750'
        }
      ],
      columns: [
        {
          label: "Abg.",
          name: "id",
          row_text_alignment: 'text-left',
        },
        {
          label: "Produktname",
          name: "billing_number",
          row_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
        },
        {
          label: "Menge",
          name: "billing_date",
          row_text_alignment: 'text-left',
        },
        {
          label: "Einzelpreis",
          name: "position_description",
          row_text_alignment: 'text-left',
        },
        {
          label: "Gesamtpreis",
          name: "amount",
          row_text_alignment: 'text-left',
          sort: true
        },
        {
          label: "Kunde",
          name: "single_price",
          row_text_alignment: 'text-left',
          filter: {
            type: "simple",
            placeholder: "Suchen..."
          },
          sort: true
        },
        {
          label: "Erstellt",
          name: "total_price",
          row_text_alignment: 'text-left',
          sort: true
        },
      ],
      config: {
        global_search:  {
          visibility:  false,
        },
        checkbox_rows: true,
        rows_selectable: true,
        show_refresh_button: false,
        show_reset_button: false,
        per_page: 10,
      }
    }
  }

}
</script>

<style lang="scss">
.accounting-create-assignment {
  .title {
    font-weight: bold;
    font-size: 15px;
  }
}
</style>
